import React from 'react'
import SEO from "../common/SEO";
import {Link} from "react-router-dom";
import { FiArrowRight } from "react-icons/fi";

import Separator from "../elements/separator/Separator";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import BlogClassicData from '../data/blog/BlogList.json';
import Logo from "../elements/logo/Logo";
import Explicacion from '../elements/landing/Explicacion';
import ServiceOne from '../elements/diseñografico/ServiceOne';
import FooterTwo from '../common/footer/FooterTwo';
import CalltoActionOne from '../elements/calltoaction/CalltoActionOne';
import ServiceThree from '../elements/landing/ServiceThree';
import AccordionOne from '../elements/accordion/AccordionOne';
import PricingThree from '../elements/landing/PricingThree';
import ContactForm from '../elements/landing/ContactForm';
import CopyrightTwo from '../common/footer/CopyrightTwo';
import ScrollTop from '../common/footer/ScrollTop';
import Whatsapp from '../components/pageToTop/contactos/Whatsapp';


var BlogListData = BlogClassicData.slice(0, 3);



const Landing = () => {
    return (
        <>
            <SEO title="Dare Agencia de diseño multimedial || Trabajamos para cumplir objetivos" />
            <main className="page-wrapper">
                {/* <HeaderTopNews /> */}
                {/* <HeaderOne btnStyle="round" HeaderSTyle="header-not-transparent" /> */}
                <div className="col-lg-6 col-md-6 col-4" style={{marginLeft:"4rem"}}>
                    <Link to ="/" >
                            <Logo 
                                image={`${process.env.PUBLIC_URL}/dare/dare-logo.png`}
                                image2={`${process.env.PUBLIC_URL}/dare/dare-logo.png`}
                            />
                    </Link>
                        </div>
                {/* Start Slider Area  */}
                <div className="slider-area slider-style-3 height-650">
                    <div className="container">
                        <div className="row row--30 align-items-center">
                            <div className="order-2 order-lg-1 col-lg-7 mt_md--50 mt_sm--50 mt_lg--30">
                                <div className="inner text-start">
                                    <h1 className="title theme-gradient display-two2">INCUBADARE 2022</h1>
                                    <h3 className="subtitle ">Impulsa tu crecimiento en el mundo digital.</h3>
                                    <p className="description">Es un programa desarrollado por Dare agencia digital y está dirigida a seleccionar emprendedores o PYMES que busquen acelerar su crecimiento y éxito, proveyéndoles apoyo referido al área de marketing digital donde se les brindara un acompañamiento integral.<br/> Pueden postularse quienes quieran ser parte y tengan un emprendimiento en marcha.</p>
                                    <div className="button-group mt--30 mt_sm--20 disp-center">
                                        <a className="btn-default btn-medium btn-icon" href="#contacto4"> ¡Postúlate ahora! </a>
                                    </div>
                                </div>
                            </div>
                            <div className="order-1 order-lg-2 col-lg-5">
                                <div className="thumbnail">
                                    <img src="./dare/test2.png" alt="Tendencias de marketing 2022" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Slider Area  */}

                <Separator />
                <br/>

{/* Start Service Area  */}
<div className="rn-service-area rn-section-gap">
    <div className="container">
        <div className="row">
            <div className="col-lg-12">
                {/* <SectionTitle
                    textAlign = "text-center"
                    radiusRounded = ""
                    subtitle = "Cumplimos objetivos"
                    title = "Beneficios a los que tendrás acceso"
                    description = "En esta convocatoria accederás a tener un equipo profesional  con el que desarrollaras y ejecutaras un plan de marketing digital rentable y competitivo permitiendo que tu empresa crezca y se posicione en el mercado digital, sin necesidad de asumir los costos fijos de salarios entre otros lo cual te brindará resultados que se reflejen en tus ingresos. "
                    /> */}
                                                        <h1 className="title theme-gradient">Beneficios a los que tendrás acceso</h1>
                                    
                                    <p className="description b1">Con este programa accederás a tener un equipo profesional  con el que desarrollaras y ejecutaras un plan de marketing digital rentable y competitivo permitiendo que tu empresa crezca y se posicione en el mercado digital, sin necesidad de asumir los costos fijos de salarios entre otros lo cual te brindará resultados que se reflejen en tus ingresos.</p>
                                    
            </div>
        </div>
        <ServiceThree cardStyle = "card-style-1" textAlign = "text-start" />
        <br/>
        <div className="button-group mt--30 mt_sm--20 disp-center">
                                        <a className="btn-default btn-medium btn-icon"  href="#contacto4"> ¡Postúlate ahora! </a>
                                    </div>
    </div>

</div>


                {/* <Separator /> */}
                {/* <AboutThree /> */}

                <Separator />
                <Explicacion/>
                
                <Separator />
                <div className="rn-accordion-area rn-section-gap">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-10 offset-lg-1">
                                    <SectionTitle
                                        textAlign = "text-center"
                                        radiusRounded = ""
                                        subtitle = "INCUBADARE 2O22"
                                        title = "Preguntas frecuentes: "
                                        description = ""
                                    />
                                </div>
                            </div>
                            <div className="row row--30 align-items-center">
                                <div className="col-lg-7 mt_md--40 mt_sm--40">
                                    <AccordionOne customStyle="rn-accordion-04" />
                                </div>
                                <div className="col-lg-5">
                    
                            <img className="w-75" src="./dare/test4.png" alt="Programador en Paraguay" />
                     
                    </div>
                            </div>
                            
                        </div>
                        <div className="button-group mt--30 mt_sm--20 disp-center">
                                        <a className="btn-default btn-medium btn-icon"  href="#contacto4"> ¡Postúlate ahora! </a>
                                    </div>
                    </div>
                    <Separator />
                    <div className="rwt-pricing-area rn-section-gap">
                        <div className="container">
                            <div className="row mb--40 mb_sm--0">
                                <div className="col-lg-12">
                                        <SectionTitle
                                            textAlign = "text-center"
                                            radiusRounded = ""
                                            subtitle = "Te acompañamos"
                                            title = "INCUBADARE 2022"
                                            description = ""
                                        />
                                </div>
                            </div>
                            <PricingThree/>
                        </div>
                    </div>
                    <Separator />
                    <br/>
                    <div className="row">
                                <div className="col-lg-12 mb--40" id="contacto4" >
                                    <SectionTitle
                                        textAlign = "text-center"
                                        radiusRounded = ""
                                        subtitle = "Impulsá tu negocio"
                                        title = "Formulario de inscripción"
                                        description = ""
                                    />
                                </div>
                                
                            </div>
                    <div className="container">
                    <div className="disp-center">
                        
                    <div className="col-lg-8 col-md-10 col-12">
        <br/>
          <ContactForm formStyle="contact-form-1" />
        </div>
        </div>
        </div>
        <Whatsapp/>
            <ScrollTop/>
              <CopyrightTwo/>
            </main>
            
        </>
    )
}
export default Landing;