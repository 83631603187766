import React from 'react';
import ScrollAnimation from "react-animate-on-scroll";


const SlpitOne = () => {
    return (
            <div className="rn-splite-style">
                <div className="split-wrapper">
                        <div className="row no-gutters radius-10 align-items-center">
                            <div className="col-lg-12 col-xl-6 col-12">
                            <div className="thumbnail">
                                    <img src="./dare/dare-ads2.png" alt="Social media marketing" />
                                </div>
                            </div>
                            <div className="col-lg-12 col-xl-6 col-12">
                                <div className="split-inner">
                                    <ScrollAnimation 
                                    animateIn="fadeInUp"
                                    animateOut="fadeInOut"
                                    animateOnce={true}>
                                        <h4 className="title">Agencia social media marketing</h4>
                                    </ScrollAnimation>
                                    <ScrollAnimation 
                                    animateIn="fadeInUp"
                                    animateOut="fadeInOut"
                                    animateOnce={true}>
                                    <p className="description">No importa si tu modelo de negocio es b2b o b2c, las redes sociales son muy relevantes para cualquier tipo de negocio hoy en día. En Dare vamos a ayudarte a cumplir tus objetivos ya sea aumentar la notoriedad, los clientes o fidelizar a los seguidores de tu marca.</p>
                                    </ScrollAnimation>

                                    
                                    <ScrollAnimation 
                                    animateIn="fadeInUp"
                                    animateOut="fadeInOut"
                                    animateOnce={true}>
                                        <ul className="split-list">
                                            <li>- Estrategia y planificación de calendario para redes sociales  </li>
                                            <li>- Creación de contenidos creativos y multimediales.</li>
                                            <li>- Gestión de las plataformas seleccionadas. </li>
                                            <li>- Creación de campañas </li>
                                            <li>- Análisis del público objetivo.</li>
                                            <li>- Analítica de redes sociales</li>
                                            



                                        </ul>
                                    </ScrollAnimation>
                                    <ScrollAnimation 
                                    animateIn="fadeInUp"
                                    animateOut="fadeInOut"
                                    animateOnce={true}>
                                        <div className="view-more-button mt--35">
                                            <a className="btn-default" href="/contacto">Contactanos</a>
                                        </div>
                                    </ScrollAnimation>
                                </div>
                        </div>
                    </div>
                </div>
            </div>
    )
}

export default SlpitOne
