import React from 'react';
import ScrollAnimation from "react-animate-on-scroll";

const TimelineData = [
    {
        id: "1",
        title: "Planificamos",
        description: "Realizamos un brainstorming en base al briefing para tener una mirada integral sobre el proyecto y concretamos los tiempos que se van a dedicar.",
    },
    {
        id: "2",
        title: "Diseñamos",
        description: "Presentamos prototipos, gráficos y todo lo necesario para que el cliente pueda ver previamente como va a ser la visibilidad y la usabilidad del proyecto.",
    },
    {
        id: "3",
        title: "Programamos",
        description: "Con el equipo decidimos que tipo de programación vamos a utilizar y comenzamos a desarrollar en base a una lista de tareas.",
    },
    {
        id: "4",
        title: "Testeamos",
        description: "Buscamos errores y nos aseguramos de que el sistema funcione correctamente. Brindamos una demo al cliente así puede probar la aplicación. Después de esta etapa la app estará lista para lanzar al mercado.",
    }
]

const TimelineTwo = ({classVar}) => {
    return (
        <div className="timeline-style-two bg-color-blackest">
            <div className="row row--0">
                {/* Start Single Progress */}
                {TimelineData.map((data , index) =>(
                    <div className={`col-lg-3 col-md-3 rn-timeline-single ${classVar}`} key={index}>
                        <div className="rn-timeline">
                            <ScrollAnimation 
                            animateIn="fadeInUp"
                            animateOut="fadeInOut"
                            animateOnce={true}>
                                <h6 className="title">{data.title}</h6>
                            </ScrollAnimation>
                            <div className="progress-line">
                                <div className="line-inner"></div>
                            </div>
                            <div className="progress-dot">
                                <div className="dot-level">
                                    <div className="dot-inner"></div>
                                </div>
                            </div>
                            <ScrollAnimation 
                            animateIn="fadeInUp"
                            animateOut="fadeInOut"
                            animateOnce={true}>
                                <p className="description">{data.description}</p>
                            </ScrollAnimation>
                        </div>
                    </div>
                ))}
                {/* End Single Progress */}
            </div>
        </div>
    )
}
export default TimelineTwo;