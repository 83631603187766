import React from 'react';
import Typed from 'react-typed';
import SEO from "../common/SEO";
import {Link} from "react-router-dom";
import { FiArrowRight } from "react-icons/fi";
import FooterTwo from '../common/footer/FooterTwo';
import Copyright from '../common/footer/Copyright';
import AboutOne from '../elements/about/AboutOne';
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import Separator from "../elements/separator/Separator";
import HeaderOne from '../common/header/HeaderOne';
import PortfolioOne from '../elements/portfolio/PortfolioOne';
import CalltoActionOne from '../elements/calltoaction/CalltoActionOne';





const BusinessConsulting2 = () => {
    return (
        <>
            <SEO title="Dare Agencia de marketing digital" />
            <main className="page-wrapper">
                {/* <HeaderTopNews /> */}
                <HeaderOne btnStyle="round" HeaderSTyle="header-not-transparent" />

           

                {/* Start Slider Area  */}
                <div className="slider-area slider-style-1 bg-transparent height-85022">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner text-center">
                                    <span className="subtitle">SOMOS</span>
                                    <h1 className="title theme-gradient display-two">Dare Agencia digital de <br /> {" "}
                                        <Typed
                                            strings={[
                                                "Marketing.",
                                                "Publicidad online.",
                                                "Social media marketing.",
                                                "Seo.",
                                                "Diseño y desarrollo web.",
                                                "Diseño Gráfico.",
                                            ]}
                                            typeSpeed={80}
                                            backSpeed={5}
                                            backDelay={1000}
                                            loop
                                        />
                                    </h1>
                                    <p className="description">Acompañamos a empresas a generar impacto en el mundo digital ¡Atrevete a ser diferente!</p>
                                    <div className="button-group">
                                    <a className="btn-default btn-medium round btn-icon" href="/#quienes-somos">Conocenos<i className="icon"><FiArrowRight /></i></a>
                                        <Link className="btn-default btn-medium btn-border round btn-icon" to="/contacto">Contactanos<i className="icon"><FiArrowRight /></i></Link> 
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Slider Area  */}

                <Separator />
               
               

                {/* Start About Area  */}
                <div id="quienes-somos">
                <AboutOne />
                </div>                            
                {/* End About Area  */}
                <Separator />
                <div className="rwt-portfolio-area rn-section-gap" id="servicios">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                    <SectionTitle
                                        textAlign = "text-center"
                                        radiusRounded = ""
                                        subtitle = "Cumplimos objetivos"
                                        title = "¿Cómo lo hacemos?"
                                        description = ""
                                    />
                            </div>
                        </div>
                        
                        <PortfolioOne Column="col-lg-6 col-md-6 col-sm-6 col-12 mt--30 portfolio no-overlay"  />
                        
                    </div>
                </div>
                {/* <Separator />                             */}

                {/* End Service Area  */}


                {/* Start Call To Action Area  */}
                <div className="rwt-callto-action-area">
                    <div className="wrapper">
                        <CalltoActionOne />
                        
                    </div>
                </div>
                <FooterTwo />
                <Copyright />
            </main>
        </>
    )
}
export default BusinessConsulting2;
