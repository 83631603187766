import React from 'react'

const Blury = () => {
    return (
        <div>
            {/* <div className="rn-gradient-circle"></div> */}
            <div className="rn-gradient-circle theme-pink"></div>
        </div>
    )
}

export default Blury
