import React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { Link } from "react-router-dom";

const dataList2 = [
  {
    image: "/images/service/email.png",
    title: "E-mail marketing",
    description:
      "There are many variations variats  of passages of Lorem Ipsum available.",
  },
];

const dataList = [
  {
    image: "/images/service/linkedin.png",
    title: "Linkedin ads",
    description:
      "There are many variations variats  of passages of Lorem Ipsum available.",
  },
  {
    image: "/images/service/pinterest.png",
    title: "Pinterest ads",
    description:
      "Passages there are many variatin Lorem Ipsum available have suffered.",
  },
  {
    image: "/images/service/twitter.png",
    title: "Twitter ads",
    description:
      "Passages there are many variatin Lorem Ipsum available have suffered.",
  },
  {
    image: "/images/service/facebook.png",
    title: " Facebook ads",
    description:
      "Passages there are many variatin Lorem Ipsum available have suffered.",
  },
  {
    image: "/images/service/instagram.png",
    title: " Instagram ads",
    description:
      "Passages there are many variatin Lorem Ipsum available have suffered.",
  },
];

const dataList3 = [
  {
    image: "/images/service/ads@2x.png",
    title: "Medios digitales",
    description:
      "There are many variations variats  of passages of Lorem Ipsum available.",
  },
  {
    image: "/images/service/busqueda@2x.png",
    title: "Anuncios de Búsqueda",
    description:
      "Passages there are many variatin Lorem Ipsum available have suffered.",
  },
  {
    image: "/images/service/display@2x.png",
    title: "Anuncios de Display",
    description:
      "Passages there are many variatin Lorem Ipsum available have suffered.",
  },
  {
    image: "/images/service/shopping@2x.png",
    title: "Anuncios de Shopping",
    description:
      "Passages there are many variatin Lorem Ipsum available have suffered.",
  },
  {
    image: "/images/service/youtube.png",
    title: "Anuncios de Youtube",
    description:
      "Passages there are many variatin Lorem Ipsum available have suffered.",
  },
];

const TabThree = () => {
  return (
    <div>
      <div className="row">
        <div className="col-lg-12">
          <Tabs>
            <div className="row row--30 align-items-center">
              <div className="col-lg-12">
                <div className="rn-default-tab style-three">
                  <div className="tab-button-panel">
                    <TabList className="tab-button">
                      <Tab>
                        <div className="rn-tab-button">
                          <button>Redes sociales</button>
                        </div>
                      </Tab>
                      <Tab>
                        <div className="rn-tab-button">
                          <button>E-mail marketing</button>
                        </div>
                      </Tab>
                      <Tab>
                        <div className="rn-tab-button">
                          <button>SEM (Google ads)</button>
                        </div>
                      </Tab>
                    </TabList>
                  </div>

                  <div className="tab-content-panel">
                    <TabPanel>
                      <div className="rn-tab-content">
                        <div className="inner text-center">
                          <div className="row align-items-center row--30">
                            <div className="col-lg-4">
                              <div className="section-title">
                                <h4 className="title">
                                  Revolucionamos los medios digitales
                                </h4>
                                <p>
                                  Tenes planes Integrales de Publicidad para
                                  vos.
                                </p>
                                <div className="read-more">
                                  <a
                                    className="btn-default btn-small"
                                    href="/contacto"
                                  >
                                    Consultanos
                                  </a>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-8 mt_md--30 mt_sm--30">
                              <div className="row row--15 mt_dec--30 service-wrapper">
                                {dataList.map((val, i) => (
                                  <div
                                    className="col-lg-6 col-md-6 col-sm-6 col-12"
                                    key={i}
                                  >
                                    <div className="card-box card-style-1 text-start">
                                      <div className="inner text-center">
                                        <div className="image">
                                          {/* <Link to="#service"> */}
                                            <img
                                              src={`${val.image}`}
                                              alt="Vende Online a todo el mundo los 365 días"
                                            />
                                          {/* </Link> */}
                                        </div>
                                        <div className="content">
                                          <h4 className="title mb--20">
                                            <Link
                                              to="#service"
                                              dangerouslySetInnerHTML={{
                                                __html: val.title,
                                              }}
                                            ></Link>
                                          </h4>
                                          {/* <p className="description b1 color-gray mb--0" dangerouslySetInnerHTML={{__html: val.description}}></p> */}
                                          {/* <Link className="btn-default btn-small" to="#service">Read More</Link> */}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </TabPanel>

                    <TabPanel>
                      <div className="rn-tab-content">
                        <div className="inner text-center">
                          <div className="row align-items-center row--30">
                            <div className="col-lg-4">
                              <div className="section-title">
                                <h4 className="title">
                                  Revolucionamos los medios digitales
                                </h4>
                                <p>
                                  Tenes planes Integrales de Publicidad para
                                  vos.
                                </p>
                                <div className="read-more">
                                  <a
                                    className="btn-default btn-small"
                                    href="/contacto"
                                  >
                                    Consultanos
                                  </a>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-8 mt_md--30 mt_sm--30">
                              <div className="row row--15 mt_dec--30 service-wrapper">
                                {dataList2.map((val, i) => (
                                  <div
                                    className="col-lg-6 col-md-6 col-sm-6 col-12"
                                    key={i}
                                  >
                                    <div className="card-box card-style-1 text-start">
                                      <div className="inner text-center">
                                        <div className="image">
                                          {/* <Link to="#service"> */}
                                            <img
                                              src={`${val.image}`}
                                              alt="las mejores empresas paraguayas"
                                            />
                                          {/* </Link> */}
                                        </div>
                                        <div className="content">
                                          <h4 className="title mb--20">
                                            <Link
                                              to="#service"
                                              dangerouslySetInnerHTML={{
                                                __html: val.title,
                                              }}
                                            ></Link>
                                          </h4>
                                          {/* <p className="description b1 color-gray mb--0" dangerouslySetInnerHTML={{__html: val.description}}></p> */}
                                          {/* <Link className="btn-default btn-small" to="#service">Read More</Link> */}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </TabPanel>
                    <TabPanel>
                      <div className="rn-tab-content">
                        <div className="inner text-center">
                          <div className="row align-items-center row--30">
                            <div className="col-lg-4">
                              <div className="section-title">
                                <h4 className="title">
                                  Revolucionamos los medios digitales
                                </h4>
                                <p>
                                  Tenes planes Integrales de Publicidad para
                                  vos.
                                </p>
                                <div className="read-more">
                                  <a
                                    className="btn-default btn-small"
                                    href="/contacto"
                                  >
                                    Consultanos
                                  </a>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-8 mt_md--30 mt_sm--30">
                              <div className="row row--15 mt_dec--30 service-wrapper">
                                {dataList3.map((val, i) => (
                                  <div
                                    className="col-lg-6 col-md-6 col-sm-6 col-12"
                                    key={i}
                                  >
                                    <div className="card-box card-style-1 text-start">
                                      <div className="inner text-center">
                                        <div className="image">
                                          {/* <Link to="#service"> */}
                                            <img
                                              src={`${val.image}`}
                                              alt="Agencia de Marketing Inmobiliario"
                                            />
                                          {/* </Link> */}
                                        </div>
                                        <div className="content">
                                          <h4 className="title mb--20">
                                            <Link
                                              to="#service"
                                              dangerouslySetInnerHTML={{
                                                __html: val.title,
                                              }}
                                            ></Link>
                                          </h4>
                                          {/* <p className="description b1 color-gray mb--0" dangerouslySetInnerHTML={{__html: val.description}}></p> */}
                                          {/* <Link className="btn-default btn-small" to="#service">Read More</Link> */}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </TabPanel>
                  </div>
                </div>
              </div>
            </div>
          </Tabs>
        </div>
      </div>
    </div>
  );
};

export default TabThree;
