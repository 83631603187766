import React from 'react';
import ScrollAnimation from "react-animate-on-scroll";

const TimelineData = [
    {
        id: "1",
        date: "Paso 1",
        title: "Briefing",
        description: "Se recauda la información necesaria sobre la empresa, sus desafíos y objetivos.",
        image: "timeline-01",
        icon:"1",
        workingStep: [
            // {
            //     stepTitle: "Gain your works",
            //     stepDescription: "Lorem ipsum dolor sit amet at consectetur adipisicing",
            // },
            // {
            //     stepTitle: "Working process",
            //     stepDescription: "Lorem ipsum dolor sit amet at consectetur adipisicing",
            // },
        ]
    },
    {
        id: "2",
        date: "Paso 2",
        title: "Reunión en equipo.",
        description: "Reunimos a nuestro equipo, seleccionamos las herramientas y estrategias correctas, creamos un calendario detallado de trabajo.",
        image: "timeline-02",
        icon:"2",
        workingStep: [
            // {
            //     stepTitle: "Follow this process",
            //     stepDescription: "Lorem ipsum dolor sit amet at consectetur adipisicing",
            // },
            // {
            //     stepTitle: "Working experience",
            //     stepDescription: "Lorem ipsum dolor sit amet at consectetur adipisicing",
            // },
        ]
    },
    {
        id: "3",
        date: "Paso 3",
        title: "Etapa de conocimiento.",
        description: "Mantenemos un orden en cada una de nuestras reuniones.",
        image: "timeline-02",
        icon:"3",
        workingStep: [
            {
                stepTitle: "Entendimiento",
                stepDescription: "Hacemos análisis profundo identificando diferentes problemas desde el punto de vista del negocio y la sociedad.",
            },
            {
                stepTitle: "Investigación",
                stepDescription: "Realizamos  un trabajo de búsqueda y diagnóstico, recopilamos y procesamos la información de datos o estudios de mercado.",
            },
        ]
    },
    {
        id: "4",
        date: "Paso 4",
        title: "Etapa de Disrupción.",
        description: "Encontramos las posibles soluciones. Analizando la viabilidad de las mismas para que sean económicamente viables y sostenibles en el tiempo.",
        image: "timeline-02",
        icon:"4",
        workingStep: [
            {
                stepTitle: "Ideación",
                stepDescription: "Presentamos a nuestro partner el diagnóstico con la investigación y las soluciones del problema.",
            },
            {
                stepTitle: "Plan de acción",
                stepDescription: "Desarrollamos un plan de acción que incluye la estrategia, la creatividad, el impacto social, performance y la medición de resultados.",
            }
        ]
    },
    {
        id: "5",
        date: "Paso 5",
        title: "Monitoreo",
        description: "Controlamos la ejecución del plan de acción para asegurarnos que cumpla con lo esperado con el objetivo final.",
        image: "timeline-02",
        icon:"5",
        workingStep: [
            // {
            //     stepTitle: "Ideación",
            //     stepDescription: "Presentamos a nuestro partner el diagnóstico con la investigación y las soluciones del problema.",
            // },
            // {
            //     stepTitle: "Plan de acción",
            //     stepDescription: "Desarrollamos un plan de acción que incluye la estrategia, la creatividad, el impacto social, performance y la medición de resultados.",
            // }
        ]
    },
]

const TimelineOne = () => {
    return (
        <div className="rn-timeline-wrapper timeline-style-one position-relative">
            <div className="timeline-line"></div>
            {/* Start Single Timeline  */}

            {TimelineData.map((data, index) =>(
                <div className="single-timeline mt--50" key={index}>
                    <div className="timeline-dot">
                        <div className="time-line-circle"></div>
                    </div>
                    <div className="single-content">
                        <div className="inner">
                            <div className="row row--30 align-items-center">
                                <div className="order-2 order-lg-1 col-lg-6 mt_md--40 mt_sm--40">
                                    <div className="content">
                                        <ScrollAnimation 
                                        animateIn="fadeInUp"
                                        animateOut="fadeInOut"
                                        animateOnce={true}>
                                            <span className="date-of-timeline">{data.date}</span>
                                        </ScrollAnimation>

                                        <ScrollAnimation 
                                        animateIn="fadeInUp"
                                        animateOut="fadeInOut"
                                        animateOnce={true}>
                                            <h2 className="title">{data.title}</h2>
                                        </ScrollAnimation>

                                        <ScrollAnimation 
                                        animateIn="fadeInUp"
                                        animateOut="fadeInOut"
                                        animateOnce={true}>
                                            <p className="description">{data.description}</p>
                                        </ScrollAnimation>

                                        <div className="row row--30">
                                            {data.workingStep.map((data, index)=>(
                                                <div className="col-lg-6 col-md-6 col-12" key={index}>
                                                    <div className="working-list">
                                                        <ScrollAnimation 
                                                        animateIn="fadeInUp"
                                                        animateOut="fadeInOut"
                                                        animateOnce={true}>
                                                            <h5 className="working-title">{data.stepTitle}</h5>
                                                        </ScrollAnimation>

                                                        <ScrollAnimation 
                                                        animateIn="fadeInUp"
                                                        animateOut="fadeInOut"
                                                        animateOnce={true}>
                                                            <p className="description">{data.stepDescription}</p>
                                                        </ScrollAnimation>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>

                                    </div>
                                </div>
                                <div className="order-1 order-lg-2 col-lg-6">
                                    <div className="thumbnail">
                                    <div className="icon-trabajamos">
                                <div className="line"></div>
                                {data.icon}
                            </div>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
                                                    <div className="read-morebtn">
                                            <ScrollAnimation 
                                            animateIn="fadeInUp"
                                            animateOut="fadeInOut"
                                            animateOnce={true}>
                                                <a className="btn-default btn-large round" href="/contacto"><span>Contactanos</span></a>
                                            </ScrollAnimation>
                                        </div>
           
            {/* End Single Timeline  */}
        </div>
    )
}

export default TimelineOne;
