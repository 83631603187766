import React from 'react'
import SEO from "../common/SEO";
import {Link} from "react-router-dom";
import { FiArrowRight } from "react-icons/fi";

import Separator from "../elements/separator/Separator";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import BlogClassicData from '../data/blog/BlogList.json';
import HeaderOne from '../common/header/HeaderOne';
import Explicacion from '../elements/diseñografico/Explicacion';
import ServiceOne from '../elements/diseñografico/ServiceOne';
import FooterTwo from '../common/footer/FooterTwo';
import CalltoActionOne from '../elements/calltoaction/CalltoActionOne';
var BlogListData = BlogClassicData.slice(0, 3);



const DiseñoDetails = () => {
    return (
        <>
            <SEO title="Dare Agencia de diseño multimedial || Trabajamos para cumplir objetivos" />
            <main className="page-wrapper">
                {/* <HeaderTopNews /> */}
                <HeaderOne btnStyle="round" HeaderSTyle="header-not-transparent" />

                {/* Start Slider Area  */}
                <div className="slider-area slider-style-3 height-650">
                    <div className="container">
                        <div className="row row--30 align-items-center">
                            <div className="order-2 order-lg-1 col-lg-7 mt_md--50 mt_sm--50 mt_lg--30">
                                <div className="inner text-start">
                                    <h1 className="title theme-gradient">Diseño gráfico</h1>
                                    <p className="description">En DARE nos encargamos que la esencia de tu marca se refleje en tu identidad visual, somos especialistas en diseños comunicativos y creativos que cumplan con los objetivos.</p>
                                    <div className="button-group mt--30 mt_sm--20">
                                        <a className="btn-default btn-medium btn-icon" href="/contacto">Solicita información<i className="icon"><FiArrowRight /></i></a>
                                    </div>
                                </div>
                            </div>
                            <div className="order-1 order-lg-2 col-lg-5">
                                <div className="thumbnail">
                                    <img src="./dare/diseno-grafico1.png" alt="Tendencias de marketing 2022" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Slider Area  */}

                <Separator />
                <Explicacion/>

                <Separator />

{/* Start Service Area  */}
<div className="rn-service-area rn-section-gap">
    <div className="container">
        <div className="row">
            <div className="col-lg-12">
                <SectionTitle
                    textAlign = "text-center"
                    radiusRounded = ""
                    subtitle = "Cumplimos objetivos"
                    title = "¿Cómo lo hacemos?"
                    description = "¿No encuentras la solución que necesitas aquí? No dudes en contactarnos nuestro equipo con seguridad podrá ayudarte. "
                    />
            </div>
        </div>
        <ServiceOne cardStyle = "card-style-1" textAlign = "text-start" />
    </div>
</div>


                {/* <Separator /> */}
                {/* <AboutThree /> */}

                <Separator />

{/* <div className="rwt-timeline-area rn-section-gap">
    <div className="container">
        <div className="row">
            <div className="col-lg-12">
                    <SectionTitle
                        textAlign = "text-center"
                        radiusRounded = ""
                        subtitle = "Etapas de desarrollo"
                        title = "Proceso de trabajo."
                    />
            </div>
        </div>
        <div className="row">
            <div className="col-lg-10 offset-lg-1 mt--50">
                <TimelineTwo classVar="" />
            </div>
        </div>
    </div>
</div> */}

                <CalltoActionOne/>
                <FooterTwo/>
            </main>
            
        </>
    )
}
export default DiseñoDetails;