import React from "react";
import Typed from "react-typed";
import { FiCheck } from "react-icons/fi";

const Explicacion = () => {
  return (
    <div className="rwt-about-area rn-section-gap">
      <div className="container">
        <div className="row row--30 align-items-center">
          <div className="col-lg-7 mt_md--40 mt_sm--40 ">
           
            <div className="content">
              <div className="section-title">
                <h2 className="title">Estrategias de marketing en DARE</h2>
                <p>
                  En Dare cuando hablamos de estrategias de marketing digital
                  nos referimos a la toma de decisiones sobre los recursos y
                  acciones a tomar en la campaña. La estrategia y la
                  planificación es el corazón de todos nuestros proyectos para
                  poder llegar a los objetivos finales de la empresa.
                </p>
                <p>
                  Las estrategias de marketing parten de los objetivos del
                  negocio. Para poder aplicar estos objetivos a la elaboración
                  de un plan de marketing, necesitamos concretarlos mediante el
                  "SMART":
                </p>

                <ul className="feature-list">
                  <li>
                    <div className="icon-check">
                      <FiCheck />
                    </div>
                    <div className="title-wrapper">
                      <h4 className="title"> Specific (específicos):</h4>
                      <p className="text">
                      Un objetivo debe referirse a un logro concreto y tangible. 
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="icon-check">
                      <FiCheck />
                    </div>
                    <div className="title-wrapper">
                      <h4 className="title"> Measurable (medibles):</h4>
                      <p className="text">
                      Definimos las herramientas de medición que se van a emplear. 
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="icon-check">
                      <FiCheck />
                    </div>
                    <div className="title-wrapper">
                      <h4 className="title"> Achievable (alcanzables):</h4>
                      <p className="text">
                      Es fundamental que los objetivos presenten un reto realista. 
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="icon-check">
                      <FiCheck />
                    </div>
                    <div className="title-wrapper">
                      <h4 className="title"> Relevant (relevantes):</h4>
                      <p className="text">
                      Los objetivos deben estar relacionados con las metas globales de la empresa y mostrar una correlación clara. 
                      </p>
                    </div>
                  </li>

                  <li>
                    <div className="icon-check">
                      <FiCheck />
                    </div>
                    <div className="title-wrapper">
                      <h4 className="title"> Time-bound (con fecha límite):</h4>
                      <p className="text">
                      Cada objetivo debe tener una fecha de inicio y de fin. 
                      </p>
                    </div>
                  </li>
                </ul>

              </div>
            </div>
          </div>

          <div className="col-lg-5">
          <div className="thumbnail">
              <img
                className="w-100"
                src="./dare/dare-estrategias14.png"
                alt="Agencia digital"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Explicacion;
