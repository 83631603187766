import React from 'react';
import SEO from "../common/SEO";
import {Link} from "react-router-dom";
import { FiArrowRight, FiCheck } from "react-icons/fi";

import HeaderOne from '../common/header/HeaderOne';
import FooterTwo from '../common/footer/FooterTwo';
import Copyright from '../common/footer/Copyright';
import Separator from "../elements/separator/Separator";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import SlpitOne from "../elements/socialmedia/SlpitOne";
import PortfolioOne from "../elements/portfolio/PortfolioOne";
import TeamFour from "../elements/socialmedia/TeamFour";
import BrandTwo from "../elements/brand/BrandTwo";
import BrandFour from '../elements/brand/BrandFour';
import BrandThree from '../elements/brand/BrandThree';
import CalltoActionOne from '../elements/calltoaction/CalltoActionOne';



const Socialmedia = () => {
    return (
        <>
            <SEO title="Dare Agencia Social Media Marketing || Trabajamos para cumplir objetivos" />
            <main className="page-wrapper">
                <HeaderOne btnStyle="round" HeaderSTyle="header-not-transparent" />

                {/* Start Slider Area  */}

                
                <div className="slider-area slider-style-1 bg-transparent height-650">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-7 col-xl-6 order-2 order-lg-1 mt_md--40 mt_sm--40">
                                <div className="inner text-start">
                                    
                                    <h1 className="title theme-gradient display-one">Social media marketing.</h1>
                                    <span className="subtitle">¿Que hacemos?</span>
                                    <ul className="list-icon">
                                        <li><span className="icon-check"><FiCheck /></span>Creamos y planificamos estrategias de acuerdo a los objetivos de la empresa.</li>
                                        <li><span className="icon-check"><FiCheck /></span>Seleccionamos las plataformas, RRSS y apps más apropiadas para ejecutarlas.</li>
                                        <li><span className="icon-check"><FiCheck /></span>Realizamos Co-branding para crear alianzas. </li>
                                        <li><span className="icon-check"><FiCheck /></span>Definimos la campaña de marketing, el calendario de publicaciones y optimizamos los presupuestos de la campaña.</li>
                                        <li><span className="icon-check"><FiCheck /></span>Medimos y analizamos todos los resultados de las campañas. </li>
                                    </ul>
                                    {/* <div className="button-group mt--40">
                                        <a className="btn-default btn-medium round btn-icon" target="_blank" href="https://themeforest.net/checkout/from_item/33571911?license=regular">Purchase Now <i className="icon"><FiArrowRight /></i></a>
                                        <Link className="btn-default btn-medium btn-border round btn-icon" to="#">Contact Us <i className="icon"><FiArrowRight /></i></Link>
                                    </div> */}
                                </div>
                            </div>
                            <div className="col-lg-5 col-xl-6 order-1 order-lg-2">
                            <div className="thumbnail">
                                    <img src="./dare/social-media_12x.png" alt="marketing en Argentina" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Separator />
                {/* Start Service Area  */}
                <div className="rn-service-area rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = "Vamos a revolucionar tu mundo digital."
                                    title = ""
                                    description = ""
                                    />
                            </div>
                        </div>

                     <div className="service-area rn-section-gapBottom">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                <div className="content">
                                    <h3 className="title">¿Por qué elegirnos?</h3>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <p className="mb--10">Somos una Agencia internacional y multidisciplinaria con presencia en Paraguay y Argentina, creemos que el marketing digital debe estar desarrollado en base a conceptos claros, sencillos, efectivos e impactantes y esto no puede sostenerse si no realiza una estrategia y un análisis con excelencia. </p>

                            </div>
                        </div>
                    </div>
                </div> 
                    </div>
                </div>
                {/* End Service Area  */}

                <SlpitOne />

                {/* <Separator /> */}
<br/><br/>
                <div className="rwt-brand-area pb--80">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center">
                                    <h3 className="title">Algunas de las redes sociales con las que trabajamos.</h3>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <BrandThree  brandStyle="brand-style-2"/>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Brand Area  */}
                <CalltoActionOne/>
                <FooterTwo />
                <Copyright />
            </main>
        </>
    )
}
export default Socialmedia;
