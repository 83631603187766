import React, { useState } from "react";
import emailjs from "emailjs-com";

const Result = () => {
  return (
    <p className="success-message">
      ¡Gracias!.Su mensaje fue enviado con éxito.
    </p>
  );
};

function ContactForm({ props, formStyle }) {
  const [result, showresult] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_nfbovmp",
        "template_x0y0ucl",
        e.target,
        "vK68K8GOIf6ePD4WE"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
    showresult(true);
  };

  setTimeout(() => {
    showresult(false);
  }, 5000);

  return (
    <form className={`${formStyle}`} action="" onSubmit={sendEmail}>
      <div className="form-group">
        <input
          type="text"
          name="fullname"
          placeholder=" Nombre y apellido "
          required
        />
      </div>

      <div className="form-group">
        <input type="text" name="empresa" placeholder="Empresa" required />
      </div>
      <div className="form-group">
        <input type="email" name="email" placeholder="Email" required />
      </div>

      <div className="form-group">
        <input type="text" name="phone" placeholder="Celular" required />
      </div>
      <div className="form-group">
        <input type="text" name="redes" placeholder="Redes sociales" required />
      </div>
      <div className="form-group">
        <input
          type="text"
          name="antiguedad"
          placeholder="Antigüedad"
          required
        />
      </div>

      <div className="form-group">
        <textarea
          name="message"
          placeholder="Resumen de la empresa y porque te gustaría participar:"
          required
        ></textarea>
      </div>

      <div className="form-group disp-center">
        <button className="btn-default btn-large ">Enviar</button>
      </div>

      <div className="form-group">{result ? <Result /> : null}</div>
    </form>
  );
}
export default ContactForm;
