import { useState } from 'react';
import {Link} from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";
import PortfolioData from "../../data/landing/PortfolioData.json";


const alldata = PortfolioData;

const ServiceThree = ({textAlign, cardStyle}) => {
    const [getAllItems] = useState(alldata);
    return (
        <div className="row row--15 service-wrapper">
              {getAllItems.map( (val) => (
                               <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12" key={val.id}>
                               <ScrollAnimation 
                               animateIn="fadeInUp"
                               animateOut="fadeInOut"
                               animateOnce={true}>
                                   <div className={`card-box ${cardStyle} ${textAlign}`}>
                                       <div className="inner">
                                       <div className="icon" style={{width:"8rem"}}>
                                               <Link to="#service">
                                               
                            <img src={`${val.portfolioImage}`} alt="agencia de marketing en Paraguay" className='icon-service-1'/>
                           
                                               </Link>
                                               </div>
                                           <div className="content">
                                               <h4 className="title mb--20"><Link to="#service" dangerouslySetInnerHTML={{__html: val.title}}></Link></h4>
                                               <p className="description b1 color-gray mb--0" dangerouslySetInnerHTML={{__html: val.body}}></p>
                                               
                                           </div>
                                       </div>
                                   </div>
                               </ScrollAnimation>
                               
                           </div>
            ))}
        </div>
    )
}
export default ServiceThree;