import React from "react";
import ContactForm from "./ContactForm";
import GoogleMapStyle from "./GoogleMapStyle";
import { FiHeadphones, FiMail, FiMapPin } from "react-icons/fi";

const ContactOne = () => {
  return (
    <>
      <div className="row row--15">
        <div className="col-lg-12">
          <div className="rn-contact-address mt_dec--30">

          </div>
        </div>
      </div>

      <div className="row mt--40 row--15">

        <div className="col-lg-7">
        <br/>
          <ContactForm formStyle="contact-form-1" />
        </div>
        <div className="col-lg-5 mt_md--30 mt_sm--30">
        <div className="column">

                <div className="rn-address">
                  <div className="icon">
                    <FiHeadphones />
                  </div>
                  <div className="inner">
                    <h4 className="title">Celular de contacto</h4>
                    <p>
                      <a href="tel:+444 555 666 777">+595 994 148800</a>
                    </p>
                  </div>
                </div>
        
            
                <div className="rn-address">
                  <div className="icon">
                    <FiMail />
                  </div>
                  <div className="inner">
                    <h4 className="title">Administración</h4>
                    <p>
                      <a href="mailto:administracion@dareagenciadigital.com">
                        administracion@dareagenciadigital.com
                      </a>
                    </p>
                    <br />
                    <h4 className="title">Comercial</h4>
                    <p>
                      <a href="mailto:comercial@dareagenciadigital.com">
                        comercial@dareagenciadigital.com
                      </a>
                    </p>
                  </div>
                </div>
         

            
                <div className="rn-address">
                  <div className="icon">
                    <FiMapPin />
                  </div>
                  <div className="inner">
                    <h4 className="title">Asunción, Paraguay.</h4>
                  </div>
                  <div className="inner">
                    <h4 className="title">Buenos Aires, Argentina.</h4>
                  </div>
                </div>
              </div>
            </div>
        </div>
      
    </>
  );
};
export default ContactOne;
