import React from 'react';
import Typed from 'react-typed';

const Explicacion = () => {
    return (
        <div className="rwt-about-area rn-section-gap">
            <div className="container">
                <div className="row row--30 align-items-center">
                    <div className="col-lg-5">
                        <div className="thumbnail">
                            <img className="w-100" src="./dare/mobile-ux (2).png" alt="SEO y SEM" />
                        </div>
                    </div>

                    <div className="col-lg-7 mt_md--40 mt_sm--40">
                        <div className="content">
                            <div className="section-title">
                                <h2 className="title">Agencia Creativa especializada en <br /> {" "}
                                    <Typed className="theme-gradient"
                                        strings={[
                                            "Desarrollo de software.",
                                            "Desarrollo de e-commerce.",
                                            "Desarrollo de sitios web.",
                                            "Desarrollo de Web App."
                                        ]}
                                        typeSpeed={80}
                                        backSpeed={5}
                                        backDelay={1000}
                                        loop
                                    />
                                </h2>
                                <p>En DARE apoyamos la transformación digital, brindando herramientas que ayudan a la integración de tecnología en todas las áreas de una empresa y así mejorar los procesos de la misma. Desarrollamos sistemas a medida que ayudan a brindar servicios de calidad a los clientes. </p>
                                <p>Muchas empresas necesitan diferentes soluciones a sus necesidades, nosotros contamos con un equipo que te va a escuchar y acompañar en todo el proceso para poder dar respuestas a estas. Creamos web Apps para que puedas tener tu plataforma adaptadas para móviles en sistema operativo iOS (Apple / iPhone) o Android (Google), como así también computadoras de escritorio.</p>
                                {/* <div className="read-more-btn mt--40">
                                    <a className="btn-default" href="#"><span>More About Us</span></a>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>   
        </div>
    )
}

export default Explicacion
