import React from 'react';
import { FiActivity, FiCast, FiMap } from "react-icons/fi";
import {Link} from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";

const ServiceList = [
    {
        icon: 'dare-link.png',
        title: 'SEO On page y Linkbuilding ',
        description: 'Está basado en la estructura y optimización para ayudar a que tu web sea legible para Google y así mejorar el posicionamiento de la misma. También realizamos estrategias para llegar a la visibilidad deseada a través del enlazado externo (Linkbuilding). '
    },
    {
        icon: 'dare-social.png',
        title: 'SEO en redes sociales',
        description: 'Usar técnicas SEO en redes sociales puede aumentar la creatividad y el valor de tus contenidos. De este modo, fortaleces la relación con tus clientes y aumenta tu comunidad.'
    },
    {
        icon: 'dare-copy.png',
        title: 'SEO de contenidos ',
        description: 'Se redacta contenido teniendo en cuenta la intención de búsqueda de tus clientes y las keywords más utilizadas en el contexto que se encuentra tu negocio. '
    },
    {
        icon: 'dare-tecnico.png',
        title: 'SEO técnico ',
        description: 'Nos ocupamos de generar ficheros robots que ayuden a indexar todas las secciones de tu web. '
    },
    {
        icon: 'dare-local.png',
        title: 'SEO local ',
        description: 'Planificamos estrategias enfocadas en posicionar un sitio web en un determinado lugar y así poder explotar todos tus puntos de ventas.'
    },
    {
        icon: 'internacional.png',
        title: 'SEO internacional ',
        description: 'Tienes un producto o un servicio que quieres vender en un mercado internacional, podemos ayudarte a que tu web se posiciones en las primeras búsquedas de esos países.'
    }
]

const ServiceOne = ({textAlign, serviceStyle}) => {
    return (
        <div className="row row--15 service-wrapper">
              {ServiceList.map( (val , i) => (
                <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                    <ScrollAnimation 
                    animateIn="fadeInUp"
                    animateOut="fadeInOut"
                    animateOnce={true}>
                        <div className={`service ${serviceStyle} ${textAlign}`}>
                            <div className="icon">
                            <img src={`./dare/${val.icon}`} alt="agencia de marketing en Argentina" className='icon-service-1'/>
                            </div>
                            <div className="content">
                                <h4 className="title w-600"><Link to="#service" dangerouslySetInnerHTML={{__html: val.title}}></Link></h4>
                                <p className="description b1 color-gray mb--0" dangerouslySetInnerHTML={{__html: val.description}}></p>
                            </div>
                        </div>
                    </ScrollAnimation>
                </div>
            ))}
        </div>
    )
}
export default ServiceOne;