import React from 'react';
import ScrollAnimation from "react-animate-on-scroll";

const TimelineData = [
    {
        id: "1",
        title: " Planificación",
        description: "Armamos una estrategia SEO en base a objetivos en la empresa, su competencia, su nicho, el mercado en el que actúa y sobre todo en esta etapa nos ocupamos de conocer a tu público objetivo.",
    },
    {
        id: "2",
        title: "Optimización",
        description: "Manos a la obra, es hora de optimizar el sitio web desde la estructura hasta el contenido. El objetivo es tener un sitio robusto de carga rápida y con contenido de calidad, útil y que cubra las necesidades de sus clientes.",
    },
    {
        id: "3",
        title: "Monitoreo y análisis",
        description: "Realizar un monitoreo y análisis de todas las etapas del SEO en indispensable para potenciar los resultados obtenidos en cada ciclo.",
    },
    {
        id: "4",
        title: "Resultados",
        description: "El trabajo SEO es continuo y competitivo, ya que Google realiza diversas actualizaciones en sus sistemas para poder estar actualizado. Por lo tanto, es muy importante hacer seguimientos de los resultados. ",
    },
]

const TimelineTwo = ({classVar}) => {
    return (
        <div className="timeline-style-two bg-color-blackest">
            <div className="row row--0">
                {/* Start Single Progress */}
                {TimelineData.map((data , index) =>(
                    <div className={`col-lg-3 col-md-3 rn-timeline-single ${classVar}`} key={index}>
                        <div className="rn-timeline">
                            <ScrollAnimation 
                            animateIn="fadeInUp"
                            animateOut="fadeInOut"
                            animateOnce={true}>
                                <h6 className="title">{data.title}</h6>
                            </ScrollAnimation>
                            <div className="progress-line">
                                <div className="line-inner"></div>
                            </div>
                            <div className="progress-dot">
                                <div className="dot-level">
                                    <div className="dot-inner"></div>
                                </div>
                            </div>
                            <ScrollAnimation 
                            animateIn="fadeInUp"
                            animateOut="fadeInOut"
                            animateOnce={true}>
                                <p className="description">{data.description}</p>
                            </ScrollAnimation>
                        </div>
                    </div>
                ))}
                {/* End Single Progress */}
            </div>
        </div>
    )
}
export default TimelineTwo;