import React from 'react';
import Typed from 'react-typed';
import { FiCheck } from "react-icons/fi";

const Explicacion = () => {
    return (
        <div className="rwt-about-area rn-section-gap">
            <div className="container">
                <div className="row row--30 align-items-center">
                    <div className="col-lg-5">
                        <div className="thumbnail">
                            <img className="w-100" src="./dare/seo-segunda.png" alt="aumentar ventas" />
                        </div>
                    </div>

                    <div className="col-lg-7 mt_md--40 mt_sm--40">
                        <div className="content">
                            <div className="section-title">
                                <h2 className="title">¿Qué es SEO?
                                </h2>
                                <p>SEO es la sigla para Search Engine Optimization, que significa "optimización para motores de búsqueda". Consiste en una serie de técnicas, disciplinas y estrategias de optimización que se implementan para que una web gane visibilidad en los buscadores y por lo tanto aumente el TRÁFICO ORGANICO. La mayor parte de las personas que realizan búsquedas en Google solo se fijan en la primera página. </p>
                                <h2 className="title"> ¿Cuál es la diferencia entre SEO y SEM? 
                                </h2>
                               
                                <ul className="feature-list">
                                    <li>
                                        <div className="icon-check">
                                            <FiCheck />
                                        </div>
                                        <div className="title-wrapper">
                                            <h4 className="title"> SEO (Posicionamiento orgánico):</h4>
                                            <p className="text">
El posicionamiento orgánico refiere al posicionamiento “no pago”, es decir, tu no pagas por estar en las primeras posiciones, sino que tu contenido y tus optimizaciones ganan esos lugares. </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="icon-check">
                                            <FiCheck />
                                        </div>
                                        <div className="title-wrapper">
                                            <h4 className="title"> SEM (Posicionamiento pago):</h4>
                                            <p className="text">Esta estrategia se basa en los anuncios “pagos”, tu sitio aparecerá en las primeras posiciones de Google en forma de anuncio, siempre y cuando concuerde con la intención de búsqueda del usuario.</p>
                                        </div>
                                    </li>
                                </ul>
                                <div className="read-more-btn mt--40">
                                    <a className="btn-default" href="/publicidad-online"><span>Agencia especialista en SEM</span></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>   
        </div>
    )
}

export default Explicacion
