import React from 'react';
import { FiActivity, FiCast, FiMap } from "react-icons/fi";
import {Link} from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";
import { FaLevelUpAlt } from 'react-icons/fa';

const ServiceList = [
    {
        icon: 'dare-sweb.png',
        title: 'Web site',
        description: '¡Ya conoces los beneficios de tener una web! Nosotros podemos ayudarte a que tengas la mejor. Nos basamos en que cada diseño tenga un objetivo en específico y cada elemento de la landing encaminen a conseguir dichos objetivos y sobre todo sea un espacio que muestre tu identidad de marca.'
    },
    {
        icon: 'dare-webapp.png',
        title: 'Web App',
        description: 'Desarrollamos software a medida, si tenes una idea o una necesidad de un proyecto que te ayude a mejorar procesos dentro de tu empresa o brindar un valor agregado a tus clientes y te gustaría desarrollarlo, nuestro equipo puede asesorarte.'
    },
    {
        icon: 'dare-ecommerce.png',
        title: 'E-commerce',
        description: 'Te ofrecemos tiendas online modernas, atractivas, compatibles con todos los dispositivos móviles. Nos encargamos del diseño, maquetación, programación y puesta en marcha como así también del mantenimiento y analítica mensual. '
    },
    {
        icon: 'dare-ux.png',
        title: 'Benchmarkig competencia y CRO UX',
        description: 'Realizamos benchmarks un proceso de análisis comparativo de sitios web de tu competencia en el que destacamos las ventajas y desventajas de elementos según criterios de diseño y contenidos. Realizamos estudios en base a la experiencia del usuario en la web asi poder mejorar el CRO (Optimización de la Tasa de Conversión). '
    },
    {
        icon: 'dare-lupa.png',
        title: 'Conten copy  & SEO: ',
        description: 'Optimizamos el sitio web y evaluamos su estructura, así como su contenido. Para esto, se utilizan las mejores prácticas y las técnicas más avanzadas para que el sitio puede posicionarse entre las primeras búsquedas. '
    }
]

const ServiceOne = ({textAlign, serviceStyle}) => {
    return (
        <div className="row row--15 service-wrapper">
              {ServiceList.map( (val , i) => (
                <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                    <ScrollAnimation 
                    animateIn="fadeInUp"
                    animateOut="fadeInOut"
                    animateOnce={true}>
                        <div className={`service ${serviceStyle} ${textAlign}`}>
                            <div className="icon">
                            <img src={`./dare/${val.icon}`} alt="agencia de marketing en Paraguay" className='icon-service-1'/>
                            </div>
                            <div className="content">
                                <h4 className="title w-600"><Link to="#service" dangerouslySetInnerHTML={{__html: val.title}}></Link></h4>
                                <p className="description b1 color-gray mb--0" dangerouslySetInnerHTML={{__html: val.description}}></p>
                            </div>
                        </div>
                    </ScrollAnimation>
                </div>
            ))}
        </div>
    )
}
export default ServiceOne;