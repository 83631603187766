import React from 'react';
import { FiFacebook, FiTwitter, FiInstagram, FiLinkedin } from "react-icons/fi";
import {Link} from "react-router-dom";

const CopyrightTwo = () => {
    return (
        <div className="copyright-area copyright-style-one variation-two">
            <div className="container">
                <div className="row align-items-center">
    
                        <div className="copyright-right text-center text-lg-right">
                            <p className="copyright-text">Copyright Todos los derechos reservados ©{new Date().getFullYear()} Dare agencia digital.</p>
                        </div>
                    </div>
                </div>
            </div>
    
    )
}
export default CopyrightTwo;