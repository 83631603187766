import React from 'react';
import Typed from 'react-typed';

const Explicacion = () => {
    return (
        <div className="rwt-about-area rn-section-gap">
            <div className="container">
                <div className="row row--30 align-items-center">
                    <div className="col-lg-5">
                        <div className="thumbnail">
                            <img className="w-100" src="./dare/dare-ads3.png" alt="Diseñador/a grafico" />
                        </div>
                    </div>

                    <div className="col-lg-7 mt_md--40 mt_sm--40">
                        <div className="content">
                            <div className="section-title">
                                <h2 className="title">¿Qué es una campaña de publicidad? 
                                
                                </h2>
                                <p>En DARE entendemos que la publicidad es la forma de obtener notoriedad, aumentar las ventas o comunicar algo, por lo tanto, el primer paso para crear una campaña publicitaria es tener una estrategia diseñada y planificada por profesionales del área para tener los mejores resultados, cumplir objetivos y cuidar los presupuestos.</p>
                                <p>El trabajo no termina en el lanzamiento de la campaña, nuestro equipo analiza las métricas de cada uno de los anuncios para poder optimizar al máximo los presupuestos y poder mejorar continuamente el ROI. </p>
                                
                                {/* <div className="read-more-btn mt--40">
                                    <a className="btn-default" href="#"><span>More About Us</span></a>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>   
        </div>
    )
}

export default Explicacion
