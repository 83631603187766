import React from 'react'
import { Accordion, Card, Button } from 'react-bootstrap';
import TimelineTwo from '../timeline/TimelineTwo';

const AccordionOne = ({customStyle}) => {
    return (
        <Accordion className={`rn-accordion-style ${customStyle}`} defaultActiveKey="0">
            <Card>
                <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="0">
                ¿QUIÉNES PUEDEN SOLICITAR?
                </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="0">
                    <Card.Body>- Cualquier persona con un emprendimiento que tenga una antigüedad entre 1 y 5 años. <br/>

- Interés de desarrollarse en el entorno digital.  </Card.Body>
                </Accordion.Collapse>
            </Card>
            <Card>
                <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="14">
                ¿CUANTO TIEMPO DURA EL APOYO?
                </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="14">
                    <Card.Body>Duración de 6 meses.</Card.Body>
                </Accordion.Collapse>
            </Card>
            <Card>
                <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="1">
                ¿HASTA CUANDO SE PUEDE  SOLICITAR? 
                </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="1">
                    <Card.Body>Primera fecha de cierre: Hasta el 31 de Octubre.</Card.Body>
                </Accordion.Collapse>
            </Card>

            <Card>
                <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="2">
                ¿PUEDO SOLICITAR PARA MAS DE UNA MARCA?
                </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="2">
                    <Card.Body>Se puede solicitar para una o más marcas siempre que estas sean tuyas.</Card.Body>
                </Accordion.Collapse>
            </Card>

            <Card>
                <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="3">
                ¿CUAL ES EL PROCESO DE SELECCIÓN?
                </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="3">
                    <Card.Body>Durante la etapa de selección, los proyectos serán revisados por un jurado evaluador compuesto por ejecutivos de DARE Agencia digital, quienes realizaran un análisis de pertinencia, se analizara que el formulario de postulación contenga toda la información necesaria de la siguiente manera:
                        <TimelineTwo/>
                    </Card.Body>
                </Accordion.Collapse>
            </Card>

        </Accordion>
    )
}
export default AccordionOne

