import React from "react";
import Typed from "react-typed";

const AboutOne = () => {
  return (
    <div className="rwt-about-area rn-section-gap">
      <div className="container">
        <div className="row row--30 align-items-center">
          <div className="col-lg-5">
            <div className="thumbnail">
              <img
                className="w-100"
                src="./dare/dare-logo.png"
                alt="About Images"
              />
            </div>
          </div>

          <div className="col-lg-7 mt_md--40 mt_sm--40">
            <div className="content">
              <div className="section-title">
                <h2 className="title">
                  Conocenos <br />{" "}
                  <Typed
                    className="theme-gradient"
                    strings={["Dare.", "Atrevete.", "Agencia."]}
                    typeSpeed={80}
                    backSpeed={5}
                    backDelay={1000}
                    loop
                  />
                </h2>
                <p>
                  Somos un equipo de profesionales dedicados a implementar
                  experiencias y contenidos únicos a través de la unión de
                  investigación, tecnología, comunicación y diseño.
                </p>
                <p>
                  {" "}
                  Desarrollamos sólidas estrategias de marketing para alcanzar
                  sus objetivos, aprovechando al máximo el alcance del mundo
                  digital. Nos centramos en construir una relación más fuerte
                  entre las personas y la marca en los canales en los que los
                  clientes están activos. 
                </p>
                <p>
                Creemos que nuestra audiencia ya no
                  está formada por receptores pasivos de contenidos, sino por
                  comunicadores interactivos que aportan sus puntos de vista
                  personales; fortaleciendo así las relaciones.
                </p>
                <div className="read-more-btn mt--40">
                  <a className="btn-default" href="/como-trabajamos">
                    <span>Más acerca de nosotros</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutOne;
