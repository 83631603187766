import React from "react";
import SEO from "../common/SEO";
import Slider from "react-slick";
import { BannerActivation } from "../utils/script";
import Separator from "../elements/separator/Separator";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import BlogClassicData from "../data/blog/BlogList.json";
import HeaderOne from "../common/header/HeaderOne";
import Explicacion from "../elements/estrategias/Explicacion";
import { FiCheck } from "react-icons/fi";
import FooterTwo from "../common/footer/FooterTwo";
import PortfolioOne from "../elements/estrategias/PortfolioOne";
import CalltoActionOne from "../elements/calltoaction/CalltoActionOne";
var BlogListData = BlogClassicData.slice(0, 3);

const BannerData = [
  {
    image: "./dare/dare-estrategias12.png",
    title: "Estrategias de marketing digital",
    description:
      "Desarrollamos planes integrales de marketing que abarquen diferentes canales y así ayudarte a tomar decisiones acertadas. Juntos aumentaremos tus conversiones. ",
  },
];

const EstrategiasDetails = () => {
  return (
    <>
      <SEO title="Dare Agencia de marketing || Trabajamos para cumplir objetivos" />
      <main className="page-wrapper">
        <HeaderOne btnStyle="round" HeaderSTyle="header-not-transparent" />

        {/* Start Slider Area  */}
        <Slider
          className="slider-area slider-style-4 slider-dot rn-slick-dot rn-slick-arrow"
          {...BannerActivation}
        >
          {BannerData.map((data, index) => (
            <div key={index} className="single-slide">
              <div
                className="height-650 bg-overlay bg_image"
                style={{
                  backgroundImage: `url(${process.env.PUBLIC_URL} ${data.image})`,
                }}
              >
                <div className="container">
                  <div className="row row--30 align-items-center">
                    <div className="order-2 order-lg-1 col-lg-7">
                      <div className="inner text-start">
                        <h1
                          className="title"
                          dangerouslySetInnerHTML={{ __html: data.title }}
                        ></h1>
                        <p
                          className="description"
                          dangerouslySetInnerHTML={{ __html: data.description }}
                        ></p>
                        {/* <div className="button-group mt--30">
                          <a
                            className="btn-default"
                            target="_blank"
                            href="https://themeforest.net/checkout/from_item/33571911?license=regular"
                          >
                            PURCHASE NOW
                          </a>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Slider>
        {/* End Slider Area  */}
        <Explicacion />
        <Separator />
        {/* Start Service Area  */}
        <div className="rn-service-area rn-section-gap">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle="Dare agencia de marketing digital y desarrollo de software."
                  title="Nuestras estrategias."
                  description=""
                />

    
<div className="container">
                <ul className="feature-list row">
                  <li>
                    <div className="icon-check">
                      <FiCheck />
                    </div>
                    <div className="title-wrapper">
                      <h4 className="title"> Eventos Digitales</h4>
                    </div>
                  </li>
                  <li>
                    <div className="icon-check">
                      <FiCheck />
                    </div>
                    <div className="title-wrapper">
                      <h4 className="title"> Insigth de marcas</h4>
                    </div>
                  </li>
                  <li>
                    <div className="icon-check">
                      <FiCheck />
                    </div>
                    <div className="title-wrapper">
                      <h4 className="title"> Manual de Marcas</h4>
                    </div>
                  </li>
                  <li>
                    <div className="icon-check">
                      <FiCheck />
                    </div>
                    <div className="title-wrapper">
                      <h4 className="title"> Asesoramiento de Marketing</h4>
                    </div>
                  </li>

                  <li>
                    <div className="icon-check">
                      <FiCheck />
                    </div>
                    <div className="title-wrapper">
                      <h4 className="title"> Campañas Integraleses</h4>
                    </div>
                  </li>
                  <li>
                    <div className="icon-check">
                      <FiCheck />
                    </div>
                    <div className="title-wrapper">
                      <h4 className="title"> Lanzamientos de Productos </h4>
                    </div>
                  </li>
                  <li>
                    <div className="icon-check">
                      <FiCheck />
                    </div>
                    <div className="title-wrapper">
                      <h4 className="title"> Desarrollo de Packaging y productos</h4>
                    </div>
                  </li>

                  <li>
                    <div className="icon-check">
                      <FiCheck />
                    </div>
                    <div className="title-wrapper">
                      <h4 className="title"> Plan de Marketing</h4>
                    </div>
                  </li>
                  <li>
                    <div className="icon-check">
                      <FiCheck />
                    </div>
                    <div className="title-wrapper">
                      <h4 className="title"> Promociones de Ventas</h4>
                    </div>
                  </li>
                </ul>
                </div>
              </div>
            </div>

          </div>
        </div>
        {/* End Service Area  */}

        
        <div className="rwt-portfolio-area rn-section-gap">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle="Dare"
                  title="Servicios"
                  description=""
                />
              </div>
            </div>
            <PortfolioOne cardStyle = "card-style-1"
                                textAlign = "text-start" />
          </div>
        </div>
        <CalltoActionOne/>
        <FooterTwo/>
      </main>
    </>
  );
};
export default EstrategiasDetails;
