import React from 'react';
import Separator from "../elements/separator/Separator";
import SEO from "../common/SEO";
import Layout from "../common/Layout";
import BreadcrumbOne from "../elements/breadcrumb/BreadcrumbOne";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import TimelineOne from "../elements/timeline/TimelineOne";
import TimelineTwo from "../elements/timeline/TimelineTwo";



const Trabajo = () => {
    return (
        <>
            <SEO title="Dare Agencia digital || Trabajamos para cumplir objetivos" />
            <Layout>
                {/* <BreadcrumbOne 
                    title="Nuestro Proceso de trabajo."
                    rootUrl="/"
                    parentUrl="Home"
                    currentUrl="Como trabajamos"
                /> */}

                <div className="main-content">

                    {/* Start Timeline Area  */}
                    <div className="rwt-timeline-area rn-section-gap">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                        <SectionTitle
                                            textAlign = "text-center"
                                            radiusRounded = ""
                                            subtitle = "¿Trabajamos juntos?"
                                            title = "Nuestro proceso de trabajo."
                                        />
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-lg-12">
                                    <TimelineOne />
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* End Timeline Area  */}








                </div>
            </Layout>
        </>
    )
}
export default Trabajo;