import React from 'react';
import { FiCheck } from "react-icons/fi";


const PricingThree = () => {
    return (
        <div className="row">
            <div className="col-lg-8 offset-lg-2">
                <div className="row row--0">
                    {/* Start PRicing Table Area  */}
                    <div className="col-lg-6 col-md-6 col-12">
                        <div className="rn-pricing style-2">
                            <div className="pricing-table-inner">
                                <div className="pricing-header">
                                    <h4 className="title">Precio actual</h4>
                                    <div className="pricing">
                                        <div className="price-wrapper">
                                            <span className="currency">GS</span>
                                            <span className="price">5.150.000</span>
                                        </div>
                                        <span className="subtitle">GS Por mes</span>
                                    </div>
                                </div>
                                <div className="pricing-body">
                                    <ul className="list-style--1">
                                        <li><FiCheck /> Estrategias de marketing digital</li>
                                        <li><FiCheck /> Social media marketing</li>
                                        <li><FiCheck /> Diseño gráfico</li>
                                        <li><FiCheck /> Seo</li>
                                        <li><FiCheck /> Publicidad online</li>
                                        <li><FiCheck /> Equipo de marketing</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* End PRicing Table Area  */}

                    {/* Start PRicing Table Area  */}
                    <div className="col-lg-6 col-md-6 col-12">
                        <div className="rn-pricing style-2 active" >
                            <div className="pricing-table-inner">
                                <div className="pricing-header">
                                    <h4 className="title" style={{color:"rgb(245 153 0)"}}>Precio INCUBADARE</h4>
                                    <div className="pricing">
                                        <div className="price-wrapper">
                                            <span className="currency">GS</span>
                                            <span className="price">2.050.000</span>
                                        </div>
                                        <span className="subtitle">GS Por mes</span>
                                    </div>
                                </div>
                                <div className="pricing-body">
                                    <ul className="list-style--1">
                                    <li><FiCheck /> Estrategias de marketing digital</li>
                                        <li><FiCheck /> Social media marketing</li>
                                        <li><FiCheck /> Diseño gráfico</li>
                                        <li><FiCheck /> Seo</li>
                                        <li><FiCheck /> Publicidad online</li>
                                        <li><FiCheck /> Equipo de marketing</li>
                                    </ul>
                                </div>
                                <div className="pricing-footer">
                                    <a className="btn-default"  href="#contacto4">¡Postúlate ahora!</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* End PRicing Table Area  */}
                </div>
            </div>
        </div>
    )
}
export default PricingThree;
