import React from 'react';
import { FiCheck } from 'react-icons/fi';
import Typed from 'react-typed';

const Explicacion = () => {
    return (
        <div className="rwt-about-area rn-section-gap">
            <div className="container">
                <div className="row row--30 align-items-center">
                    <div className="col-lg-5">
                        <div className="thumbnail">
                            <img className="w-100" src="./dare/dare-logo.png" alt="Programador en Paraguay" />
                        </div>
                    </div>

                    <div className="col-lg-7 mt_md--40 mt_sm--40">
                        <div className="content">
                            <div className="section-title">
                            <h1 className="title theme-gradient">¿Quienes somos?</h1>
                                <p className="description b1">Somos un equipo de profesionales dedicados a implementar experiencias y contenidos únicos a través de la unión de investigación, tecnología, comunicación y diseño. 

<br/>Desarrollamos sólidas estrategias de marketing para alcanzar sus objetivos, aprovechando al máximo el alcance del mundo digital. Nos centramos en construir una relación más fuerte entre las personas y la marca en los canales en los que los clientes están activos. 

<br/>Creemos que nuestra audiencia ya no está formada por receptores pasivos de contenidos, sino por comunicadores interactivos que aportan sus puntos de vista personales; fortaleciendo así las relaciones. </p>
                            </div>
                        </div>
                    </div>
                    <div className="button-group mt--30 mt_sm--20 disp-center">
                                        <a className="btn-default btn-medium btn-icon"  href="#contacto4"> ¡Postúlate ahora! </a>
                                    </div>
                </div>
            </div>   
        </div>
    )
}

export default Explicacion
