import React from 'react';
import { FiCheck } from 'react-icons/fi';
import Typed from 'react-typed';

const Explicacion = () => {
    return (
        <div className="rwt-about-area rn-section-gap">
            <div className="container">
                <div className="row row--30 align-items-center">
                    <div className="col-lg-5">
                        <div className="thumbnail">
                            <img className="w-100" src="./dare/diseno-grafico2.png" alt="Programador en Paraguay" />
                        </div>
                    </div>

                    <div className="col-lg-7 mt_md--40 mt_sm--40">
                        <div className="content">
                            <div className="section-title">
                                <h2 className="title">Comunica a través del diseño multimedial 
                                </h2>
                                <p>La audiencia evolucionó en los canales digitales, y sabemos lo importante que es el impacto visual de tu marca para que tu negocio escale en los medios. Creamos contenido de calidad para todas las plataformas que revoluciona el mundo digital. Amamos lo que hacemos por lo tanto siempre estamos listos para nuevos desafíos y siempre darte una respuesta. Nuestras bases:</p>
                                <ul className="feature-list">
                  <li>
                    <div className="icon-check">
                      <FiCheck />
                    </div>
                    <div className="title-wrapper">
                      <h4 className="title"> Versatilidad:</h4>
                      <p className="text">
               Adaptamos los diseños al medio.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="icon-check">
                      <FiCheck />
                    </div>
                    <div className="title-wrapper">
                      <h4 className="title"> Comunicación:</h4>
                      <p className="text">
                      Nuestros diseños cuentan historias. 
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="icon-check">
                      <FiCheck/>
                    </div>
                    <div className="title-wrapper">
                      <h4 className="title"> Actualidad:</h4>
                      <p className="text">
                      El mercado es muy competitivo por lo tanto siempre estamos actualizándonos.  
                      </p>
                    </div>
                  </li>

                </ul>

                                {/* <div className="read-more-btn mt--40">
                                    <a className="btn-default" href="#"><span>More About Us</span></a>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>   
        </div>
    )
}

export default Explicacion
