import React from "react";
import SEO from "../common/SEO";
import { Link } from "react-router-dom";
import { FiArrowRight } from "react-icons/fi";

import Separator from "../elements/separator/Separator";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import BlogClassicData from "../data/blog/BlogList.json";
import HeaderOne from "../common/header/HeaderOne";
import Explicacion from "../elements/publicidad/Explicacion";
import ServiceOne from "../elements/publicidad/ServiceOne";
import FooterTwo from "../common/footer/FooterTwo";
import TimelineOne from "../elements/timeline/TimelineOne";
import TimelineTwo from "../elements/publicidad/TimelineTwo";
import TabThree from "../elements/publicidad/TabThree";
import CalltoActionOne from "../elements/calltoaction/CalltoActionOne";
var BlogListData = BlogClassicData.slice(0, 3);

const PublicidadDetails = () => {
  return (
    <>
      <SEO title="Dare Agencia de Pautas digitales || Trabajamos para cumplir objetivos" />
      <main className="page-wrapper">
        {/* <HeaderTopNews /> */}
        <HeaderOne btnStyle="round" HeaderSTyle="header-not-transparent" />

        {/* Start Slider Area  */}
        <div className="slider-area slider-style-3 height-650">
          <div className="container">
            <div className="row row--30 align-items-center">
              <div className="order-2 order-lg-1 col-lg-7 mt_md--50 mt_sm--50 mt_lg--30">
                <div className="inner text-start">
                  <h1 className="title theme-gradient">
                    Agencia Publicitaria online
                  </h1>
                  <p className="description">
                    Estructuramos y planificamos detalladamente las campañas de
                    anuncios en los canales más convenientes para alcanzar los
                    objetivos, basándonos en el diseño, la optimización y la
                    medición de cada una de ellas y generar así un mayor volumen
                    de leeds.{" "}
                  </p>
                  <div className="button-group mt--30 mt_sm--20">
                    <a
                      className="btn-default btn-medium btn-icon"
                      href="/contacto"
                    >
                      Solicita información
                      <i className="icon">
                        <FiArrowRight />
                      </i>
                    </a>
                  </div>
                </div>
              </div>
              <div className="order-1 order-lg-2 col-lg-5">
                <div className="thumbnail">
                  <img src="./dare/dare-ads.png" alt="Consultora de desarrollo y programación" />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Slider Area  */}

        <Separator />
        <Explicacion />

        <Separator />

        {/* Start Service Area  */}
        <div className="rn-service-area rn-section-gap">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle="Tipos de campañas"
                  title="¿Cómo lo hacemos?"
                  description="Estos son los formatos de publicidad digital con las que trabajamos para cumplir tus objetivos."
                />
              </div>
            </div>
            <TabThree />
          </div>
        </div>

        {/* <Separator /> */}
        {/* <AboutThree /> */}

        <CalltoActionOne/>
        <FooterTwo />
      </main>
    </>
  );
};
export default PublicidadDetails;
